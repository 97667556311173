(function() {
  'use strict';

  angular
    .module('connectsx.settings.personal', []);

})();

(function() {
  'use strict';

  angular
    .module('connectsx.settings.personal')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.settings.personal', {
        url: '/personal',
        templateUrl: 'app/modules/settings/personal/personal.html',
        controller:'PersonalController',
        controllerAs:'vm'
      });

  }

})();

(function () {
  'use strict';

  /* global angular */

  angular
    .module('connectsx.settings.personal')
    .controller('PersonalController', PersonalController);

  PersonalController.$inject = ['currentAuth', 'Accounts', 'Toast', 'Manufacturers', '$log'];

  function PersonalController(currentAuth, Accounts, Toast, Manufacturers, $log) {

    var vm = this;

    vm.save = save;
    vm.isUpdating = false;
    vm.saveManufacturer = saveManufacturer;
    vm.isSavingManufacturer = false;

    activate();

    function activate() {
      vm.account = currentAuth;

      if (vm.account.manufacturer) {
        vm.manufacturer = Manufacturers.get({ objectKey: vm.account.manufacturer.key });
      }

    }

    function save() {

      if (vm.isUpdating) return;
      $log.info('Account save');
      vm.isUpdating = true;

      Accounts.update({ objectKey:vm.account.key }, vm.account, success, error);

      function success() {
        vm.isUpdating = false;
        Toast.success('Account saved.')
      }

      function error(response) {
        vm.isUpdating = false;
        $log.error(response);
        Toast.error(response.data.error.message);
      }

    }

    function saveManufacturer() {

      if (!angular.isObject(vm.manufacturer)) {
        return;
      }

      if (!angular.isString(vm.manufacturer.key)) {
        return;
      }

      if (vm.isSavingManufacturer) {
        return;
      }

      vm.isSavingManufacturer = true;

      Manufacturers.update({ objectKey: vm.manufacturer.key }, vm.manufacturer, success, error);

      function success(response) {
        vm.manufacturer = response;
        vm.isSavingManufacturer = false;
      }

      function error(response) {
        vm.isSavingManufacturer = false;
        $log.error(response);
        Toast.error(response);
      }


    }

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.settings.misc', []);

})();

(function() {
  'use strict';

  angular
    .module('connectsx.settings.misc')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.settings.misc', {
        url: '/misc',
        templateUrl: 'app/modules/settings/misc/settings.misc.html',
        controller:'SettingsMiscController',
        controllerAs:'vm'
      });

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.settings.misc')
    .controller('SettingsMiscController', SettingsMiscController);

    SettingsMiscController.$inject = ['$log', 'Misc', 'Toast'];

  function SettingsMiscController($log, Misc, Toast) {

    var vm = this;

    vm.fixInventory = fixInventory;
    vm.copyGuides = copyGuides;
    vm.copyProducts = copyProducts;
    vm.copyLayers = copyLayers;
    vm.copyTrays = copyTrays;
    vm.fixInventoryProducts = fixInventoryProducts;
    vm.fixTemplateProducts = fixTemplateProducts;
    vm.deleteDuplicateProducts = deleteDuplicateProducts;

    activate();

    function activate() {
    }

    function fixInventory() {
      Misc.fixInventory(null, null, function () {
          Toast.success('Started to fix inventory.');
      }, Toast.error);
    }

    var copyParams = {
        from:"ahBzfmNvbm5lY3RzeC1jb3JlcjILEgxPcmdhbml6YXRpb24iB2Zsb3dfZngMCxIMTWFudWZhY3R1cmVyIgdmbG93X2Z4DA",
        to:"ahBzfmNvbm5lY3RzeC1jb3JlckALEgxPcmdhbml6YXRpb24iDmNvbm5lY3RzeF9kZW1vDAsSDE1hbnVmYWN0dXJlciIOY29ubmVjdHN4X2RlbW8M"
    };

    function fixInventoryProducts() {
        Misc.fixInventoryProducts({}, null, function(){
            Toast.success('Successfully started to fix inventory products.');
        }, Toast.error);
    }

    function fixTemplateProducts() {
        Misc.fixTemplateProducts({}, null, function(){
            Toast.success('Successfully started to fix template products.');
        }, Toast.error);
    }

    function deleteDuplicateProducts() {
        Misc.deleteDuplicateProducts({}, null, function(){
            Toast.success('Successfully started to delete duplicate products.');
        }, Toast.error);
    }

    function copyGuides() {
        Misc.copyGuides(copyParams, null, function(){
            Toast.success('Successfully copied guides.');
        }, Toast.error);
    }

    function copyProducts() {
        Misc.copyProducts(copyParams, null, function(){
            Toast.success('Successfully copied products.');
        }, Toast.error);
    }

    function copyLayers() {
        Misc.copyLayers(copyParams, null, function(){
            Toast.success('Successfully copied layers.');
        }, Toast.error);
    }

    function copyTrays() {
        Misc.copyTrays(copyParams, null, function(){
            Toast.success('Successfully copied trays.');
        }, Toast.error);
    }

  }

})();

(function () {
    'use strict';

    angular
        .module('connectsx.settings.misc')
        .factory('Misc', Misc);

    Misc.$inject = ['$resource', 'adminUrl'];

    function Misc($resource, adminUrl) {

        var resourceUrl = adminUrl + '/v1/task/:objectKey';
        var fixInventoryUrl = adminUrl + '/v1/task/inventory';
        var fixInventoryProductsUrl = adminUrl + '/v1/task/inventory/products';
        var fixTemplateProductsUrl = adminUrl + '/v1/task/template/products';
        var deleteDuplicateProductsUrl = adminUrl + '/v1/task/products/delete';
        var copyGuides = adminUrl + '/v1/copy/guides';
        var copyProducts = adminUrl + '/v1/copy/products';
        var copyTrays = adminUrl + '/v1/copy/trays';
        var copyLayers = adminUrl + '/v1/copy/layers';

        return $resource(resourceUrl, null,
            {
                fixInventory: {url: fixInventoryUrl, method: 'POST'},
                fixTemplateProducts: {url: fixTemplateProductsUrl, method: 'POST'},
                fixInventoryProducts: {url: fixInventoryProductsUrl, method: 'POST'},
                deleteDuplicateProducts: {url: deleteDuplicateProductsUrl, method: 'POST'},
                copyGuides: {url: copyGuides, method: 'POST'},
                copyProducts: {url: copyProducts, method: 'POST'},
                copyTrays: {url: copyTrays, method: 'POST'},
                copyLayers: {url: copyLayers, method: 'POST'},
            });
    }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.orgs.manufacturers', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.orgs.manufacturers')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.orgs.manufacturers', {
        url: '/manufacturers',
        templateUrl: 'app/modules/orgs/manufacturers/manufacturers.html',
        controller: 'ManufacturersController',
        controllerAs: 'vm',
        resolve: { 'currentAuth' : ['AuthResolver', function(AuthResolver){ return AuthResolver(); }]}
      })
  }

})();



(function () {
    'use strict';

    angular
        .module('connectsx.orgs.manufacturers')
        .factory('Manufacturers', Manufacturers);

    Manufacturers.$inject = ['$resource', 'adminUrl'];

    function Manufacturers($resource, adminUrl) {

        var resourceUrl = adminUrl + '/v1/org/:objectKey';

        return $resource(resourceUrl, null,
            {
                get: {method: 'GET'},
                insert: {method: 'POST'},
                update: {method: 'PUT'},
                list: {method: 'GET'}
            });
    }

})();

(function () {
    'use strict';

    /* global angular */

    angular
        .module('connectsx.orgs.manufacturers')
        .controller('ManufacturersController', ManufacturersController);

    ManufacturersController.$inject = ['$scope', '$log', '$stateParams', '$mdDialog', 'Toast', 'Orgs'];

    function ManufacturersController($scope, $log, $stateParams, $mdDialog, Toast, Orgs) {

        $scope.$emit('loading', false);

        var vm = this;

        vm.submit = submit;
        vm.addUser = addUser;

        activate();

        function activate() {
            Orgs.list({ type: 'Manufacturer' }, function (response) {
                vm.manufacturers = response.items || [];
            }, function (error) {
                Toast.error(error);
            });
        }

        function submit() {

            if (vm.isSubmitting) return;

            var name = vm.manufacturerName;
            if (!angular.isString(name) || name.length === 0) {
                Toast.error("Manufacturer name is required.");
                return;
            }

            var key = vm.manufacturerKey;
            if (!angular.isString(key) || key.length === 0) {
                Toast.error("Manufacturer name is required.");
                return;
            }

            vm.isSubmitting = true;
            Orgs.insert(null, {objectId: key, name: name, type: 'Manufacturer'}, function (response) {
                vm.isSubmitting = false;
                vm.manufacturers.splice(0, 0, response);
            }, function (error) {
                vm.isSubmitting = false;
                Toast.error(error);
            });

        }


        function addUser(manufacturer) {
            $mdDialog.show({
                locals: {'org': manufacturer},
                controller: 'AddUserController',
                templateUrl: 'app/modules/users/add.user.tmpl.html',
                clickOutsideToClose: true
            });
        }

    }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.orgs.distributors', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.orgs.distributors')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.orgs.distributors', {
        url:'/distributors',
        templateUrl:'app/modules/orgs/distributors/distributors.html',
        controller: 'DistributorsController',
        controllerAs: 'vm',
      });
  }

})();



(function () {
    'use strict';

    /* global angular */

    angular
        .module('connectsx.orgs.distributors')
        .controller('DistributorsController', DistributorsController);

    DistributorsController.$inject = ['$scope', '$log', '$stateParams', '$mdDialog', 'Orgs', 'Toast'];

    function DistributorsController($scope, $log, $stateParams, $mdDialog, Orgs, Toast) {

        $scope.$emit('loading', false);

        var vm = this;

        vm.submit = submit;
        vm.addUser = addUser;

        activate();

        function activate() {
            Orgs.list({type: 'Distributor'}, function (response) {
                vm.distributors = response.items || [];
            }, function (error) {
                Toast.error(error);
            });
        }

        function submit() {

            if (vm.isSubmitting) return;

            var name = vm.distributorName;
            if (!angular.isString(name) || name.length === 0) {
                Toast.error("Distributor name is required.");
                return;
            }

            var key = vm.distributorKey;
            if (!angular.isString(key) || key.length === 0) {
                Toast.error("Distributor name is required.");
                return;
            }

            vm.isSubmitting = true;
            Orgs.insert(null, {objectId: key, name: name, type: 'Distributor'}, function (response) {
                vm.isSubmitting = false;
                vm.distributors.splice(0, 0, response);
            }, function (error) {
                vm.isSubmitting = false;
                Toast.error(error);
            });

        }


        function addUser(distributor) {
            $mdDialog.show({
                locals: {'org': distributor},
                controller: 'AddUserController',
                templateUrl: 'app/modules/users/add.user.tmpl.html',
                clickOutsideToClose: true
            });
        }
    }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.users', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.users')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.users', {
        url: '/users',
        templateUrl: 'app/modules/users/users.html',
        controller: 'UsersController',
        controllerAs: 'vm',
        resolve: { 'currentAuth' : ['AuthResolver', function(AuthResolver){ return AuthResolver(); }]}
      })
  }

})();



(function () {
    'use strict';

    /* global angular */

    angular
        .module('connectsx.users')
        .controller('UsersController', UsersController);

    UsersController.$inject = ['$scope', '$log', 'Invitations', 'Accounts', 'Toast'];

    function UsersController($scope, $log, Invitations, Accounts, Toast) {

        $scope.$emit('loading', false);

        var vm = this;
        vm.isLoading = false;

        vm.invite = invite;

        activate();

        function activate() {

        }

        function invite(email) {
            $log.info(email);

            var invitation = {
                email: email,
                accountType: "Connectsx"
            };

            vm.isLoading = true;

            Invitations.insert(null, invitation, success, error);

            function success(response) {
                vm.isLoading = false;
                Toast.success('Successfully invited ' + response.email + '!');
            }

            function error(response) {
                vm.isLoading = false;
                Toast.error(response);
            }

        }

    }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.users')
    .controller('AddUserController', AddUserController);

  AddUserController.$inject = ['$scope', '$mdDialog', '$log', 'Invitations', 'Toast', 'org'];

  /* eslint-disable angular/controller-as */
  function AddUserController($scope, $mdDialog, $log, Invitations, Toast, org) {

    $scope.roles = [];

    $scope.org = org;
    $scope.roles = [org.type];

    $scope.loading = false;

    $scope.submit = function(email, role) {

      if (angular.isUndefined(email) || angular.isUndefined(role)) return;
      $scope.loading = true;

      Invitations.insert({
        email: email,
        accountType: role,
        organization: org
      }, submitSuccess, submitError);

    };

    function submitSuccess(invitation) {
      $scope.loading = false;
      Toast.success('Successfully invited ' + invitation.email + '!');
      $mdDialog.cancel();
    }

    function submitError(response) {
      Toast.error(response);
      $log.error(angular.toJson(response));
    }

    $scope.cancel = function() {
      $mdDialog.cancel();
    };

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.settings', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.settings')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.settings', {
        abstract: true,
        url: '/settings',
        templateUrl: 'app/modules/settings/settings.html',
        controller: 'SettingsController',
        controllerAs: 'vm'
      });
  }

})();



(function () {
  'use strict';

  /* global angular */

  angular
    .module('connectsx.settings')
    .controller('SettingsController', SettingsController);

  SettingsController.$inject = ['$state', '$scope', '$http', 'Auth'];

  function SettingsController($state, $scope, $http, Auth) {

    var vm = this;

    vm.tabs = [
      { heading: 'Personal', active: false, state:'index.settings.personal' },
      { heading: 'Misc', active: false, state:'index.settings.misc' },
    ];

    vm.onTabSelected = onTabSelected;
    vm.logout = logout;

    activate();

    function activate() {

      $scope.$emit('loading', false);

      /// derive current tab from state
      for (var i=0; i< vm.tabs.length; i++) {
        var tab = vm.tabs[i];
        if (tab.state === $state.current.name) {
          onTabSelected(vm.tabs[i], false);
          break;
        }
      }
    }

    function logout() {

      $http.defaults.headers.common['Authorization'] = '';
      Auth.$signOut();
      $state.go('auth.login');

    }

    function onTabSelected(tab, changeState) {

      if (angular.isUndefined(changeState)) {
        changeState = true;
      }

      for (var i=0; i< vm.tabs.length; i++) {
        vm.tabs[i].active = false;
      }
      tab.active = true;
      vm.title = tab.heading;

      if (changeState) {
        $state.go(tab.state);
      }
    }
  }
})();


(function() {
  'use strict';

  angular
    .module('connectsx.payments', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.payments')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.payments', {
        url: '/payments',
        templateUrl: 'app/modules/payments/payments.html',
        controller: 'PaymentsController',
        controllerAs: 'vm',
        resolve: { 'currentAuth' : ['AuthResolver', function(AuthResolver){ return AuthResolver(); }]}
      })
  }

})();



(function() {
  'use strict';

  angular
    .module('connectsx.payments')
    .factory('Payments', Payments);

    Payments.$inject = ['$resource', 'adminUrl'];

  function Payments($resource, adminUrl) {

    var resourceUrl = adminUrl + '/v1/payment/:objectKey';

    return $resource(resourceUrl, null,
      {
        get: { method: 'GET' },
        update: { method:'PUT', url: resourceUrl + 'payment/:objectKey/status' },
        list: { method: 'GET' }
      });
  }

})();

(function () {
    'use strict';

    /* global angular */

    angular
        .module('connectsx.payments')
        .controller('PaymentsController', PaymentsController);

    PaymentsController.$inject = ['$scope', '$log', 'Payments', 'Toast'];

    function PaymentsController($scope, $log, Payments, Toast) {

        $scope.$emit('loading', false);

        var vm = this;
        vm.updateStatus = updateStatus;

        vm.statuses = [
            "Incomplete",
            "Pending",
            "Approved",
            "Sent",
            "Declined"
        ];

        activate();

        function activate() {
            Payments.list(null, function (response) {
                var payments = response.items || [];
                angular.forEach(payments, function (payment) {
                    /// set term description
                    if (!angular.isNumber(payment.selectedOption)) {
                        payment.termDescription = 'None';
                        payment.due = null;
                        return;
                    }
                    var option = payment.options[payment.selectedOption];
                    if (!angular.isObject(option) ||
                        !angular.isNumber(option.days) ||
                        !angular.isNumber(option.rate)) {
                        payment.termDescription = 'Unknown';
                        payment.due = null;
                        return;
                    }
                    /// set due
                    var desc = '%' + (option.rate * 100);
                    desc += ' (' + option.days + ' Days)';
                    payment.termDescription = desc;
                    var commission = (payment.invoiceTotal * payment.commissionRate)
                    var adjustment = commission * option.rate;
                    payment.due = commission - adjustment;
                });
                vm.payments = payments;
            }, function (error) {
                $log.error(error);
                Toast.error(error);
            });
        }

        function updateStatus(payment) {
            if (angular.isUndefined(payment) || !angular.isString(payment.key)) {
                return;
            }
            $log.info(payment.status);

            var params = {
                objectKey: payment.key
            };

            Payments.update(params, payment, successCallback, errorCallback);

            function successCallback(value) {
                payment.modifiedDate = value.modifiedDate;
                Toast.success('Updated status to ' + value.status + '!');
            }

            function errorCallback(error) {
                Toast.error(error);
            }
        }
    }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.orgs', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.orgs')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.orgs', {
        abstract: true,
        url: '/orgs',
        templateUrl: 'app/modules/orgs/orgs.html',
        controller: 'OrgsController',
        controllerAs: 'vm'
      });
  }

})();



(function () {
    'use strict';

    angular
        .module('connectsx.orgs')
        .factory('Orgs', Orgs);

    Orgs.$inject = ['$resource', 'adminUrl'];

    function Orgs($resource, adminUrl) {

        var resourceUrl = adminUrl + '/v1/org/:objectKey';

        return $resource(resourceUrl, null,
            {
                get: {method: 'GET'},
                remove: {method: 'DELETE'},
                insert: {method: 'POST'},
                update: {method: 'PUT'},
                list: {method: 'GET'}
            });
    }

})();

(function () {
  'use strict';

  /* global angular */

  angular
    .module('connectsx.orgs')
    .controller('OrgsController', OrgsController);

    OrgsController.$inject = ['$state', '$scope', '$http', 'Auth'];

  function OrgsController($state, $scope, $http, Auth) {

    var vm = this;

    vm.tabs = [
      { heading: 'Manufacturers', active: false, state:'index.orgs.manufacturers' },
      { heading: 'Distributors', active: false, state:'index.orgs.distributors' },
    ];

    vm.onTabSelected = onTabSelected;
    vm.logout = logout;

    activate();

    function activate() {

      $scope.$emit('loading', false);

      /// derive current tab from state
      for (var i=0; i< vm.tabs.length; i++) {
        var tab = vm.tabs[i];
        if (tab.state === $state.current.name) {
          onTabSelected(vm.tabs[i], false);
          break;
        }
      }
    }

    function logout() {

      $http.defaults.headers.common['Authorization'] = '';
      Auth.$signOut();
      $state.go('auth.login');

    }

    function onTabSelected(tab, changeState) {

      if (angular.isUndefined(changeState)) {
        changeState = true;
      }

      for (var i=0; i< vm.tabs.length; i++) {
        vm.tabs[i].active = false;
      }
      tab.active = true;
      vm.title = tab.heading;

      if (changeState) {
        $state.go(tab.state);
      }
    }
  }
})();


(function() {
  'use strict';

  angular
    .module('connectsx', [
      'ngAnimate',
      'ngCookies',
      'ngSanitize',
      'ngMessages',
      'ngAria',
      'ngResource',
      'ngMaterial',
      'ngMaterialDatePicker',
      'ui.router',
      'ui.bootstrap',
      'ui.slimscroll',
      'firebase',
      'gridshore.c3js.chart',
      'angularUUID2',
      'angular-ladda',
      'dndLists',
      'connectsx.auth',
      'connectsx.home',
      'connectsx.errors',
      'connectsx.orgs',
      'connectsx.orgs.manufacturers',
      'connectsx.orgs.distributors',
      'connectsx.payments',
      'connectsx.users',
      'connectsx.settings',
      'connectsx.settings.personal',
      'connectsx.settings.misc'
    ]);

})();

(function() {
  'use strict';

  /* global angular */

  angular
    .module('connectsx')
    .controller('MainController', MainController);

  MainController.$inject = ['$log', 'currentAuth'];

  function MainController($log, currentAuth) {

    var vm = this;
    vm.null = null;

    vm.name = currentAuth.name;
    vm.accountType = currentAuth.accountType;
    $log.info(angular.toJson(currentAuth));

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth', []);

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .factory('targetService', targetService);

  targetService.$inject = [];

  function targetService() {

    var instance = {
      targetState: undefined,
      targetParams: undefined
    };

    instance.reset = function() {
      instance.targetState = undefined;
      instance.targetParams = undefined;
    };

    return instance;

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .controller('LoginController', LoginController);

  LoginController.$inject = ['$scope', '$q', '$log', '$state', 'Auth', 'Accounts', 'targetService'];

  function LoginController($scope, $q, $log, $state, Auth, Accounts, targetService) {

    var vm = this;

    vm.user = {};
    vm.error = '';
    vm.login = login;
    vm.loading = false;

    function login(form, user) {

      if (!form.$valid) return;

      vm.error = '';
      vm.loading = true;
      $scope.$emit('loading', true);

      Auth.$signInWithEmailAndPassword(user.email, user.password)
        .then( ensureAccountExists )
        .then( finish )
        .catch( showError );

      function finish() {

        if (angular.isDefined(targetService.targetState) &&
          angular.isDefined(targetService.targetState.name)) {

          $state.go(targetService.targetState.name, targetService.targetParams);
          targetService.reset();

        }
        else {
          $state.go('index.home');
        }
      }
    }

    function showError(error) {

      vm.loading = false;
      $scope.$emit('loading', false);
      vm.error = error.message || error;
      $log.error("Authentication failed:", error);

    }

    function ensureAccountExists(firebaseUser) {
      return Accounts.me().$promise.then(function () {
        return firebaseUser;
      }, function(error) {
        return $q.reject(error.data.error);
      });
    }

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .controller('ForgotController', ForgotController);

  ForgotController.$inject = ['$scope', '$log', 'Auth'];

  function ForgotController($scope, $log, Auth) {

    var vm = this;

    vm.resetPassword = resetPassword;

    function resetPassword(form, email) {

      if (!form.$valid || angular.isUndefined(email)) return;

      vm.loading = true;
      $scope.$emit('loading', true);

      Auth.$sendPasswordResetEmail(email).then(function() {

        $scope.$emit('loading', false);
        $log.info('Password reset email sent');
        vm.message = "Sent! Check your email.";

      }).catch(function(error) {
        $log.error("Error: ", error);
        vm.error = error.message;
      });
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .factory('Auth', Auth);

  Auth.$inject = ['$firebaseAuth'];

  function Auth($firebaseAuth) {
    return $firebaseAuth();
  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('auth', {
        url:'',
        templateUrl:'app/modules/auth/auth.html',
        controller:'AuthController',
        controllerAs:'vm'
      })
      .state('auth.login', {
        url:'/login',
        templateUrl:'app/modules/auth/login.html',
        controller:'LoginController',
        controllerAs:'vm'})
      .state('auth.claim', {
        url:'/claim?email&invitation',
        templateUrl:'app/modules/auth/auth.claim.html',
        controller:'AuthClaimController',
        controllerAs:'vm'})
      .state('auth.forgot', {
        url:'/forgot',
        templateUrl:'app/modules/auth/forgot.html',
        controller:'ForgotController',
        controllerAs:'vm'})
      .state('auth.action', {
        url: '/auth/action?mode&oobCode',
        templateUrl: 'app/modules/auth/auth.action.html',
        controller: 'AuthActionController',
        controllerAs: 'vm'
      });
  }

})();



'use strict';

(function () {

  angular
    .module('connectsx')
    .factory('AuthResolver', AuthResolver);

  AuthResolver.$inject = ['Auth', 'Accounts', '$q'];

  function AuthResolver(Auth, Accounts, $q) {

    return function () {

      function onAuth() {
        return Accounts.me().$promise;
      }

      function onError() {
        return $q.reject('AUTH_REQUIRED');
      }

      /// rejects with AUTH_REQUIRED
      return Auth.$requireSignIn()
        .then(onAuth)
        .catch(onError);

    };
  }

})();

'use strict';

(function () {

  angular
    .module('connectsx')
    .factory('FirebaseTokenInterceptor', FirebaseTokenInterceptor);

  FirebaseTokenInterceptor.$inject = ['Auth', '$q'];

  function FirebaseTokenInterceptor(Auth, $q) {

    return {

      request: function(config) {

        if (!(~config.url.indexOf('api/admin'))) {
          return config;
        }

        var deferred = $q.defer();

        function onAuth(currentAuth) {
          return currentAuth.getToken(false).then(onToken, onError);
        }

        function onToken(token) {
          config.headers['Authorization'] = 'Bearer ' + token;
          deferred.resolve(config);
        }

        function onError() {
          return deferred.resolve(config);
        }

        Auth.$requireSignIn().then(onAuth, onError);
        return deferred.promise;

      }
    };
  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .controller('AuthController', AuthController);

  AuthController.$inject = ['$scope'];

  function AuthController($scope) {

    var vm = this;

    vm.loading = false;

    $scope.$on('loading', function(event, isLoading) {
      vm.loading = isLoading;
    });

  }

})();

(function () {
    'use strict';

    angular
        .module('connectsx.auth')
        .controller('AuthClaimController', AuthClaimController);

    AuthClaimController.$inject = ['Auth', 'Accounts', '$state', '$stateParams', '$scope', '$log', '$http'];

    function AuthClaimController(Auth, Accounts, $state, $stateParams, $scope, $log, $http) {

        var vm = this;

        vm.user = {};
        vm.error = '';
        vm.loading = false;

        if (angular.isDefined($stateParams.email)) {
            vm.user.email = $stateParams.email;
        }

        $log.info('Invitation: ' + $stateParams.invitation);

        vm.register = register;
        vm.login = login;

        function register(form, user) {

            if (!form.$valid) return;

            vm.loading = true;
            $scope.$emit('loading', true);

            Auth.$createUserWithEmailAndPassword(user.email, user.password)
                .then(function (currentAuth) {
                    return currentAuth.getToken(false);
                })
                .then(function (token) {
                    $http.defaults.headers.common.Authorization = 'Bearer ' + token;
                    return Accounts.signup({invitation: $stateParams.invitation}, user).$promise;
                })
                .then(function () {
                    $scope.$emit('loading', false);
                    $state.go('index.home');
                })
                .catch(handleError);

        }

        function login(form, user) {

            if (!form.$valid) return;

            vm.loading = true;
            $scope.$emit('loading', true);

            Auth.$signInWithEmailAndPassword(user.email, user.password)
                .then(function (currentAuth) {
                    return currentAuth.getToken(false);
                })
                .then(function (token) {
                    $http.defaults.headers.common.Authorization = 'Bearer ' + token;
                    return Accounts.signup({invitation: $stateParams.invitation}, user).$promise;
                })
                .then(function () {
                    $scope.$emit('loading', false);
                    $state.go('index.home');
                })
                .catch(handleError);

        }

        function handleError(error) {
            vm.loading = false;
            $scope.$emit('loading', false);
            $log.error("Error: ", error);
            vm.error = error.message;
        }

    }

})();

(function() {
  'use strict';
  /* global firebase */
  /* global angular */

  angular
    .module('connectsx.auth')
    .controller('AuthActionController', AuthActionController);

  AuthActionController.$inject = ['$scope', '$stateParams', '$state', '$q', '$log', 'Auth'];

  function AuthActionController($scope, $stateParams, $state, $q, $log, Auth) {

    var vm = this;

    /// TODO: Check back with https://github.com/firebase/angularfire/issues/764 for when
    /// 3.x.x methods are made available in angular fire, or figure out a better way to wrap
    /// these methods in our own service.
    var __auth = firebase.auth();

    vm.mode = $stateParams.mode;
    var oobCode = $stateParams.oobCode;

    if (angular.isUndefined(vm.mode) || angular.isUndefined(oobCode)) {
      $state.go('auth.login');
      return;
    }

    vm.password = '';
    vm.error = '';
    vm.verify = verify;
    vm.resetPassword = resetPassword;

    activate();

    function activate() {

      if (vm.mode === 'verifyEmail') {

        $scope.$emit('loading', true);
        $q.when(__auth.applyActionCode(oobCode)).then(function() {
          $state.go('index.home');
        }).catch(showError);

      } else if (vm.mode === 'resetPassword') {

        $scope.$emit('loading', true);
        $q.when(__auth.verifyPasswordResetCode(oobCode)).then(function(email) {
          $scope.$emit('loading', false);
          vm.email = email;
        }).catch(showError);

      }
    }

    function showError(error) {
      $scope.$emit('loading', false);
      $log.error(error.message);
      vm.error = error.message;
    }

    function verify() {
      var firebaseUser = Auth.$getAuth();
      if (firebaseUser === null) {
        $state.go('auth.login');
        return;
      }
      firebaseUser.sendEmailVerification().then(function() {
        vm.message = "Sent! Check your email.";
      });
    }

    function resetPassword() {

      if (angular.isUndefined(vm.email) || angular.isUndefined(vm.password)) {
        return;
      }

      $scope.$emit('loading', true);

      $q.when(__auth.confirmPasswordReset(oobCode, vm.password)).then(function() {
        Auth.$signInWithEmailAndPassword(vm.email, vm.password).then(function() {
          $state.go('index.home');
          $scope.$emit('loading', false);
        }).catch(showError);
      }).catch(showError);

    }

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.auth')
    .factory('Accounts', Accounts);

  Accounts.$inject = ['$resource', 'adminUrl'];

  function Accounts($resource, adminUrl) {

    var baseUrl = adminUrl + '/v1/account/';
    var resourceUrl = baseUrl + ':objectKey';
    var meUrl = baseUrl + 'me';
    var signupUrl = baseUrl + 'signup';

    return $resource(resourceUrl, null,
      {
        me:     { url:meUrl , method: 'GET' },
        get:    { method: 'GET' },
        remove: { method: 'DELETE' },
        signup: { url:signupUrl, method: 'POST' },
        insert: { method: 'POST' },
        update: { method:'PUT' },
        list:   { method: 'GET' }
      });
  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.home', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.home')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.home', {
        url: '/home',
        templateUrl: 'app/modules/home/home.html',
        controller: 'HomeController',
        controllerAs: 'vm',
        resolve: { 'currentAuth' : ['AuthResolver', function(AuthResolver){ return AuthResolver(); }]}
      })
  }

})();



(function () {
  'use strict';

  /* global angular */

  angular
    .module('connectsx.home')
    .controller('HomeController', HomeController);

  HomeController.$inject = ['$timeout', '$scope', '$log', 'Toast'];

  function HomeController($timeout, $scope, $log, Toast) {

    $scope.$emit('loading', false);

    var vm = this;

    activate();

    function activate() {

    }

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx.errors', []);

})();

(function () {
  'use strict';

  angular
    .module('connectsx.errors')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider'];

  function routerConfig($stateProvider) {

    $stateProvider
      .state('index.404', {
        url:'/404',
        templateUrl:'app/errors/404.html',
        controller: 'ErrorsController',
        controllerAs: 'vm',
      });
  }

})();



(function () {
  'use strict';

  /* global angular */

  angular
    .module('connectsx.errors')
    .controller('ErrorsController', ErrorsController);

  ErrorsController.$inject = ['$scope', '$log'];

  function ErrorsController($scope, $log) {

    $scope.$emit('loading', false);

    var vm = this;


    activate();

    function activate() {
      vm.null = null;
      $log.info("errors.controller.js init");
    }

  }

})();

(function() {
  'use strict';

  /* global angular */

  angular
    .module('connectsx')
    .factory('Toast', Toast);

  Toast.$inject = ['$mdToast', '$document', '$log'];

  function Toast($mdToast, $document, $log) {


    function success(message) {

      var parent = angular.element($document[0].getElementById('footer'));
      $log.info(angular.toJson(parent));

      $mdToast.show(
        $mdToast.simple()
          .parent(parent)
          .toastClass('csx-toast')
          .textContent(message)
      );
    }

    function error(error) {


      var parent = angular.element($document[0].getElementById('footer'));
      $log.info(angular.toJson(parent));

      var message = 'Something went wrong!';
      if (angular.isString(error)) {
        message = error;
      }
      else if (angular.isObject(error)){
        message = error.data.error.message || error.message || message;
      }

      $mdToast.show(
        $mdToast.simple()
          .parent(parent)
          .toastClass('csx-toast error-toast')
          .textContent(message)
      );

    }

    return {
      success : success,
      error : error
    };
  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx')
    .factory('Invitations', Invitations);

    Invitations.$inject = ['$resource', 'adminUrl'];

  function Invitations($resource, adminUrl) {

    var baseUrl = adminUrl + '/v1/invitation/';
    var resourceUrl = baseUrl + ':objectKey';

    return $resource(resourceUrl, null,
      {
        get:    { method: 'GET' },
        insert: { method: 'POST' },
        list:   { method: 'GET' }
      });
  }

})();

(function () {
  'use strict';

  angular.module('connectsx')
    .directive('csxLoading', csxLoading);

  csxLoading.$inject = [];

  function csxLoading() {
    return {
      restrict: 'A',
      transclude:true,
      scope: {
        loading: '=csxIsLoading'
      },
      templateUrl: 'app/directives/loading.template.html',
      link: function(scope, element, attrs) {
        /// keep width based on initial content so that loading state doesn't
        /// jump around
        var width = element.prop('offsetWidth');
        var diameter = (element.prop('offsetHeight') || 35) - 10;
        scope.diameter = Math.max(15, diameter);
        var otherCss = element.css('cssText');
        attrs.$set('style', 'width: ' + width + 'px;' + otherCss);
      }
    };
  }

})();

(function () {
  'use strict';
  /* global angular */

  angular
    .module('connectsx')
    .filter('bigNum', bigNum);

  function bigNum() {

    return function (number) {

      if (number === null) return null;
      if (number === 0) return "0";
      if (angular.isUndefined(number)) return "0";

      var fractionSize = 1;

      var abs = Math.abs(number);
      var rounder = Math.pow(10, fractionSize);
      var isNegative = number < 0;
      var key = '';
      var powers = [
        {key: "Q", value: Math.pow(10, 15)},
        {key: "T", value: Math.pow(10, 12)},
        {key: "B", value: Math.pow(10, 9)},
        {key: "M", value: Math.pow(10, 6)},
        {key: "K", value: 1000}
      ];

      for (var i = 0; i < powers.length; i++) {

        var reduced = abs / powers[i].value;

        reduced = Math.round(reduced * rounder) / rounder;

        if (reduced >= 1) {
          abs = reduced;
          key = powers[i].key;
          break;
        }
      }

      return (isNegative ? '-' : '') + abs + key;

    }
  }

})();

// (function () {
//   'use strict';
//
//   // angular
//   //   .module('connectsx')
//   //   .filter("bigNum", bigNum);
//   //
//   // function bigNum() {
//   //   return (number, fractionSize) => {
//   //
//   //     if (number === null) return null;
//   //     if (number === 0) return "0";
//   //
//   //     if (!fractionSize || fractionSize < 0)
//   //       fractionSize = 1;
//   //
//   //     var abs = Math.abs(number);
//   //     var rounder = Math.pow(10, fractionSize);
//   //     var isNegative = number < 0;
//   //     var key = '';
//   //     var powers = [
//   //       {key: "Q", value: Math.pow(10, 15)},
//   //       {key: "T", value: Math.pow(10, 12)},
//   //       {key: "B", value: Math.pow(10, 9)},
//   //       {key: "M", value: Math.pow(10, 6)},
//   //       {key: "K", value: 1000}
//   //     ];
//   //
//   //     for (var i = 0; i < powers.length; i++) {
//   //
//   //       var reduced = abs / powers[i].value;
//   //
//   //       reduced = Math.round(reduced * rounder) / rounder;
//   //
//   //       if (reduced >= 1) {
//   //         abs = reduced;
//   //         key = powers[i].key;
//   //         break;
//   //       }
//   //     }
//   //
//   //     return (isNegative ? '-' : '') + abs + key;
//   //   };
//   // }
//
// })();

(function () {
  'use strict';

  angular.module('connectsx')
    .directive('csxBack', csxBack);

  csxBack.$inject = ['$window'];

  function csxBack($window) {
    return {
      restrict: 'A',
      link: function (scope, elem) {
        elem.bind('click', function () {
          $window.history.back();
        });
      }
    };
  }

})();

(function () {
  'use strict';

  /* global angular */

  angular
    .module('connectsx')
    .run(runBlock);

  runBlock.$inject = ['$rootScope', '$log', '$state', 'targetService'];

  function runBlock($rootScope, $log, $state, targetService) {

    /// We can catch the error thrown when the $requireSignIn promise is rejected and redirect.
    var destroyCallback = $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {

      if (error && angular.isString(error)) {

        event.preventDefault();

        /// cache target state to go there after login
        targetService.targetState = toState;
        targetService.targetParams = toParams;

        switch (error) {
          case 'AUTH_REQUIRED':
            $state.go('auth.login');
            return;
          case 'EMAIL_NOT_VERIFIED':
            $state.go('auth.action', { mode: "needsVerification" });
            return;
        }
        $state.go('auth.login');
      }
    });

    $rootScope.$on('$destroy', destroyCallback);

    $log.debug('ConnectSx runBlock end');

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx')
    .controller('IndexController', IndexController);

  IndexController.$inject = ['$scope'];

  function IndexController($scope) {

    var vm = this;
    vm.isLoading = true;

    // vm.auth = currentAuth;
      vm.auth = {};
      vm.auth.name = "Josh Day";

    $scope.$on('loading', function(event, isLoading) {
      vm.isLoading = isLoading;
    });

  }

})();

(function() {
  'use strict';

  angular
    .module('connectsx')
    // .constant('adminUrl', 'https://20180724t132207-dot-connectsx-core.appspot.com/_ah/api/admin');
    .constant('adminUrl', 'https://connectsx-core.appspot.com/_ah/api/admin');
    // .constant('adminUrl', 'https://connectsx-core-stage.appspot.com/_ah/api/admin');
})();

/* global firebase */
'use strict';

(function () {

  angular
    .module('connectsx')
    .config(routerConfig);

  routerConfig.$inject = ['$stateProvider', '$httpProvider', '$urlRouterProvider', '$locationProvider', '$mdThemingProvider'];

  function routerConfig($stateProvider, $httpProvider, $urlRouterProvider, $locationProvider, $mdThemingProvider) {

    $mdThemingProvider.definePalette('connectsxPalette', {
      '50': 'ede7f6',
      '100': 'd1c4e9',
      '200': 'b39ddb',
      '300': '9575cd',
      '400': '7e57c2',
      '500': '5B2D61',
      '600': '5e35b1',
      '700': '512da8',
      '800': '4527a0',
      '900': '311b92',
      'A100': 'b388ff',
      'A200': '7c4dff',
      'A400': '651fff',
      'A700': '6200ea',
      // '50': '5B2D61',
      // '100': '5B2D61',
      // '200': '5B2D61',
      // '300': '5B2D61',
      // '400': '5B2D61',
      // '500': '5B2D61',
      // '600': '5B2D61',
      // '700': '5B2D61',
      // '800': '5B2D61',
      // '900': '5B2D61',
      // 'A100': '5B2D61',
      // 'A200': '5B2D61',
      // 'A400': '5B2D61',
      // 'A700': '5B2D61',
      'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
                                          // on this palette should be dark or light

      'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
        '200', '300', '400', 'A100'],
      'contrastLightColors': undefined    // could also specify this if default was 'dark'
    });

    $mdThemingProvider.theme('default')
      .primaryPalette('connectsxPalette');


    // Initialize Firebase
    var config = {
      apiKey: "AIzaSyBdH1qnELts_qZpEuP_w5ONNttqdiGda6U",
      authDomain: "connectsx-production.firebaseapp.com",
      databaseURL: "https://connectsx-production.firebaseio.com",
      projectId: "connectsx-production",
      storageBucket: "connectsx-production.appspot.com",
      messagingSenderId: "764280099480"
    };

    firebase.initializeApp(config);

    $locationProvider.html5Mode(true);

    $httpProvider.interceptors.push('FirebaseTokenInterceptor');

    $stateProvider
      .state('index', {
        abstract: true,
        url: '/index',
        templateUrl: 'app/content.html',
        controller: 'MainController',
        controllerAs: 'main',
        resolve: {
          'currentAuth': ['AuthResolver', function (AuthResolver) {
            return AuthResolver();
          }]
        }
      });


    $urlRouterProvider.otherwise('/index/home');

  }

})();

angular.module("connectsx").run(["$templateCache", function($templateCache) {$templateCache.put("app/content.html","<nav class=\"navbar navbar-default navbar-fixed-top\"><div class=\"container-fluid\"><div class=\"navbar-header\"><button type=\"button\" class=\"navbar-toggle collapsed\" data-toggle=\"collapse\" data-target=\"#csx-nav-toggle\" aria-expanded=\"false\"><span class=\"sr-only\">Toggle navigation</span> <span class=\"icon-bar\"></span> <span class=\"icon-bar\"></span> <span class=\"icon-bar\"></span></button></div><img class=\"navbar-logo\" src=\"../../assets/images/csx-logo.svg\"> <span class=\"navbar-brand\" style=\"color:white; padding-left:36px;\">Admin</span><div class=\"collapse navbar-collapse\" id=\"csx-nav-toggle\"><ul class=\"nav navbar-nav\"><li><a ui-sref=\"index.orgs.manufacturers\"><i class=\"fa fa-building-o\"></i> Organizations</a></li><li><a ui-sref=\"index.payments\"><i class=\"fa fa-dollar\"></i> Rapid Payments</a></li><li><a ui-sref=\"index.users\"><i class=\"fa fa-users\"></i> Users</a></li></ul><ul class=\"nav navbar-nav navbar-settings\"><li><span style=\"color:white;\" class=\"navbar-brand\">{{ main.name }} ({{main.accountType }})</span></li><li><a ui-sref=\"index.settings.personal\"><i class=\"fa fa-cog\"></i></a></li></ul></div></div></nav><md-content id=\"page-wrapper\" class=\"{{$state.current.name}}\" ng-controller=\"IndexController as index\" flex=\"\"><div ng-show=\"!index.isLoading\" ui-view=\"\" layout-fill=\"\"></div><div ng-if=\"index.isLoading\" class=\"loading\"><div class=\"sk-spinner sk-spinner-chasing-dots\"><div class=\"sk-dot1\"></div><div class=\"sk-dot2\"></div></div></div></md-content><div id=\"footer\" class=\"footer fixed_full\"><div><strong>ConnectSx</strong> &copy; 2018</div></div>");
$templateCache.put("app/directives/loading.template.html","<div layout=\"row\" flex=\"\" layout-align=\"center\"><div ng-show=\"loading\"><md-progress-circular ng-show=\"loading\" md-diameter=\"{{ diameter }}\"></md-progress-circular></div><div ng-hide=\"loading\" ng-transclude=\"\"></div></div>");
$templateCache.put("app/modules/auth/auth.action.html","<div ng-switch=\"vm.mode\"><div ng-switch-when=\"resetPassword\"><h3>Reset Password</h3><span class=\"help-block m-b-none\">{{vm.email}}</span><form class=\"m-t\" role=\"form\"><md-input-container class=\"md-block\" style=\"margin-top: 35px;\"><label>Password</label> <input type=\"password\" ng-model=\"vm.password\" required=\"\" ng-disabled=\"vm.loading\"></md-input-container><button type=\"submit\" ng-click=\"vm.resetPassword()\" class=\"btn btn-outline btn-primary full-width m-b\" ng-disabled=\"vm.loading\">Login</button></form></div><div ng-switch-when=\"needsVerification\"><h3>Verify Email Address</h3><a class=\"btn btn-sm btn-white btn-block\" ng-click=\"vm.verify()\">Resend Verification Email</a></div><div ng-switch-when=\"verifyEmail\"><h3>Verifying Email Address</h3></div></div><span class=\"help-block m-b-none error-block\">{{vm.error}}</span>");
$templateCache.put("app/modules/auth/auth.claim.html","<h3>Claim Account</h3><form name=\"form\" class=\"m-t\" role=\"form\" style=\"margin-top: 45px;\"><md-input-container class=\"md-block\"><label>Name</label> <input type=\"text\" ng-model=\"vm.user.name\" required=\"\" ng-disabled=\"vm.loading\"></md-input-container><md-input-container class=\"md-block\"><label>Email</label> <input type=\"email\" ng-model=\"vm.user.email\" required=\"\" ng-disabled=\"vm.loading\"></md-input-container><md-input-container class=\"md-block\"><label>Password</label> <input type=\"password\" ng-model=\"vm.user.password\" required=\"\" ng-disabled=\"vm.loading\"> <span class=\"help-block m-b-none error-block\">{{vm.error}}</span></md-input-container><div layout=\"row\"><button type=\"submit\" ng-click=\"vm.register(form, vm.user)\" class=\"btn btn-outline btn-primary full-width m-b\" ng-disabled=\"vm.loading\">Signup</button><div style=\"width:24px;\"></div><button type=\"button\" ng-click=\"vm.login(form, vm.user)\" class=\"btn btn-outline btn-primary full-width m-b\" ng-disabled=\"vm.loading\">Login</button></div></form>");
$templateCache.put("app/modules/auth/auth.html","<div class=\"loginColumns animated fadeIn\"><div class=\"row\"><div class=\"col-md-6\" style=\"text-align:center;\"><div class=\"image-logo loading-logo\" ng-switch=\"vm.loading\"><div ng-switch-when=\"true\"><div class=\"sk-spinner sk-spinner-chasing-dots\"><div class=\"sk-dot1\"></div><div class=\"sk-dot2\"></div></div></div><div ng-switch-default=\"\"><img src=\"../../assets/images/colored-logo-img.png\"></div></div><div class=\"type-logo\"><img src=\"../../assets/images/colored-logo-type.png\"></div><p class=\"text-center\">Simplifying tracking, management, and<br>use of orthopaedic implants.</p></div><div class=\"col-md-6\"><ui-view></ui-view></div></div><hr><div class=\"row\"><div class=\"col-md-6\">ConnectSx</div><div class=\"col-md-6 text-right\">&copy; 2018</div></div></div>");
$templateCache.put("app/modules/auth/forgot.html","<h3>Forgot password</h3><p>Enter your email address and we will send you a link to reset you password.</p><div class=\"row\"><div class=\"col-lg-12\"><form class=\"m-t\" role=\"form\" name=\"form\"><md-input-container class=\"md-block\"><label>Email</label> <input type=\"email\" ng-model=\"vm.email\" required=\"\" ng-disabled=\"vm.loading\"> <span class=\"help-block m-b-none error-block\">{{ vm.error }}</span></md-input-container><button type=\"submit\" ng-click=\"vm.resetPassword(form, vm.email)\" class=\"btn btn-outline btn-primary full-width m-b\" ng-disabled=\"vm.loading\">Reset Password</button> <span class=\"help-block m-b-none primary\">{{ vm.message }}</span></form></div></div>");
$templateCache.put("app/modules/auth/login.html","<h3>Sign in</h3><form name=\"form\" class=\"m-t\" role=\"form\" style=\"margin-top: 45px;\"><md-input-container class=\"md-block\"><label>Email</label> <input type=\"email\" ng-model=\"vm.user.email\" required=\"\" ng-disabled=\"vm.loading\"></md-input-container><md-input-container class=\"md-block\"><label>Password</label> <input type=\"password\" ng-model=\"vm.user.password\" required=\"\" ng-disabled=\"vm.loading\"> <span class=\"help-block m-b-none error-block\">{{vm.error}}</span></md-input-container><button type=\"submit\" ng-click=\"vm.login(form, vm.user)\" class=\"btn btn-outline btn-primary full-width m-b\" ng-disabled=\"vm.loading\">Login</button><p class=\"text-center\"><a ui-sref=\"auth.forgot\"><small>Forgot password?</small></a></p></form>");
$templateCache.put("app/modules/errors/404.html","<div class=\"wrapper wrapper-content animated fadeIn\" layout-align=\"center center\"><h1>404</h1><p>The page you are looking for cannot be found.</p><p>Check the URL and try again.</p><p>If you think something is wrong let us know <a>here</a>.</p></div>");
$templateCache.put("app/modules/home/home.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div class=\"row\"><div class=\"col-sm-12\"><div class=\"ibox-title\"><h2>Dashboard</h2><hr></div></div></div></div>");
$templateCache.put("app/modules/orgs/orgs.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div layout=\"row\"><div flex=\"\" style=\"margin-bottom:30px;\"><h2>Organizations</h2></div></div><div class=\"row\"><div class=\"col-md-12\"><uib-tabset><uib-tab ng-repeat=\"tab in vm.tabs\" heading=\"{{tab.heading}}\" active=\"tab.active\" select=\"vm.onTabSelected(tab)\"></uib-tab></uib-tabset><ui-view></ui-view></div></div></div>");
$templateCache.put("app/modules/payments/payments.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div class=\"row\"><div class=\"col-sm-12\"><div class=\"ibox-title\"><h2>Rapid Payments</h2><hr></div></div></div><div class=\"row\"><div class=\"col-sm-12\"><div class=\"row\" ng-repeat=\"payment in vm.payments\"><div class=\"col-sm-3\"><h3>Rep</h3><h5>{{ payment.ancestor.name }}</h5><p>{{ payment.ancestor.email }}</p></div><div class=\"col-sm-3\"><h3>Case</h3><h5>{{ payment.caseName }}</h5><p>{{ payment.poNumber }}</p></div><div class=\"col-sm-3\"><h3>Payment</h3><h5>Status: {{ payment.status }}</h5><p>Invoice Total: {{ payment.invoiceTotal | currency }}</p><p>Commission Rate: %{{ payment.commissionRate * 100 }} ({{ (payment.invoiceTotal * payment.commissionRate) | currency }})</p><p>Selected Term: {{ payment.termDescription }}</p><p>Due: {{ payment.due | currency }}</p></div><div class=\"col-sm-3\"><h3>Update</h3><md-select ng-model=\"payment.status\" ng-change=\"vm.updateStatus(payment)\"><md-option ng-value=\"status\" ng-repeat=\"status in vm.statuses\">{{ status }}</md-option></md-select><p>Last Updated: {{ payment.modifiedDate | date:\'MM/dd/yyyy h:mm a\' }}</p></div><div class=\"col-md-12\"><hr></div></div></div></div></div>");
$templateCache.put("app/modules/settings/settings.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div layout=\"row\"><div flex=\"\" style=\"margin-bottom:30px;\"><h2>Settings</h2></div><div style=\"margin-top:20px;\"><button class=\"btn btn-sm btn-outline btn-primary\" ng-click=\"vm.logout()\">Logout</button></div></div><div class=\"row\"><div class=\"col-md-12\"><uib-tabset><uib-tab ng-repeat=\"tab in vm.tabs\" heading=\"{{tab.heading}}\" active=\"tab.active\" select=\"vm.onTabSelected(tab)\"></uib-tab></uib-tabset><ui-view></ui-view></div></div></div>");
$templateCache.put("app/modules/users/add.user.tmpl.html","<md-dialog aria-label=\"Invite User\" style=\"min-width: 500px;\"><form ng-cloak=\"\"><md-toolbar><div class=\"md-toolbar-tools\"><h2 style=\"color:white;\">Invite User ({{ org.name }})</h2><span flex=\"\"></span><md-button class=\"md-icon-button\" ng-click=\"cancel()\"><i class=\"fa fa-times\"></i></md-button></div></md-toolbar><md-dialog-content><div class=\"md-dialog-content\"><div class=\"row\"><div class=\"col-sm-6\" layout=\"row\"><md-input-container flex=\"100\"><label>Email</label> <input type=\"email\" ng-model=\"email\" required=\"\"></md-input-container></div><div class=\"col-sm-6\" layout=\"row\"><md-input-container flex=\"100\"><label>Role</label><md-select aria-label=\"role\" ng-model=\"role\" required=\"\"><md-option ng-value=\"option\" ng-repeat=\"option in roles\">{{ option }}</md-option></md-select></md-input-container></div></div></div></md-dialog-content><md-dialog-actions layout=\"row\"><span flex=\"\"></span><md-button ng-click=\"submit(email, role)\" class=\"md-primary\">Add</md-button></md-dialog-actions></form></md-dialog>");
$templateCache.put("app/modules/users/users.html","<div class=\"wrapper wrapper-content animated fadeIn\"><h1>Users</h1><div class=\"row\"><div class=\"col-sm-3\"><md-input-container class=\"md-block\"><label>ConnectSx Admin Email</label> <input ng-model=\"vm.email\" required=\"\"></md-input-container></div></div><div class=\"row\"><div class=\"col-sm-1\"><button csx-loading=\"\" csx-is-loading=\"vm.isLoading\" class=\"btn btn-sm btn-outline btn-primary\" ng-click=\"vm.invite(vm.email)\">Invite</button></div></div></div>");
$templateCache.put("app/modules/orgs/manufacturers/manufacturers.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div class=\"row\"><div class=\"col-sm-6\" layout=\"row\"><form name=\"createForm\"><md-input-container><label>Key</label> <input required=\"\" name=\"manufacturerKey\" ng-model=\"vm.manufacturerKey\"><div ng-messages=\"createForm.manufacturerKey.$error\"><div ng-message=\"required\">This is required.</div></div></md-input-container><md-input-container><label>Name</label> <input required=\"\" name=\"manufacturerName\" ng-model=\"vm.manufacturerName\"><div ng-messages=\"createForm.manufacturerName.$error\"><div ng-message=\"required\">This is required.</div></div></md-input-container><button style=\"width:100px; margin-left: 50px; margin-top:10px;\" type=\"submit\" ng-click=\"vm.submit()\" csx-loading=\"\" csx-is-loading=\"vm.isSubmitting\" class=\"btn btn-outline btn-default create pull-right margin-left-20\"><i class=\"fa fa-plus\"></i> Create</button></form></div><div class=\"col-sm-12\"><div class=\"row\" ng-repeat=\"manufacturer in vm.manufacturers\"><div class=\"col-sm-11\"><h3>{{ manufacturer.name }}</h3><span class=\"detail\">{{ manufacturer.objectId }}</span><p>{{ manufacturer.address }}</p><button class=\"btn btn-outline btn-default create\" ng-click=\"vm.addUser(manufacturer)\"><i class=\"fa fa-plus\"></i> Add User</button></div><div class=\"col-md-12\"><hr></div></div></div></div></div>");
$templateCache.put("app/modules/orgs/distributors/distributors.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div class=\"row\"><div class=\"col-sm-6\" layout=\"row\"><form name=\"createForm\"><md-input-container><label>Key</label> <input required=\"\" name=\"distributorKey\" ng-model=\"vm.distributorKey\"><div ng-messages=\"createForm.distributorKey.$error\"><div ng-message=\"required\">This is required.</div></div></md-input-container><md-input-container><label>Distributor Name</label> <input required=\"\" name=\"distributorName\" ng-model=\"vm.distributorName\"><div ng-messages=\"createForm.distributorName.$error\"><div ng-message=\"required\">This is required.</div></div></md-input-container><button style=\"width:100px; margin-left: 50px; margin-top:10px;\" type=\"submit\" ng-click=\"vm.submit()\" csx-loading=\"\" csx-is-loading=\"vm.isSubmitting\" class=\"btn btn-outline btn-default create pull-right margin-left-20\"><i class=\"fa fa-plus\"></i> Create</button></form></div><div class=\"col-sm-12\"><div class=\"row\" ng-repeat=\"distributor in vm.distributors\"><div class=\"col-sm-11\"><h3>{{ distributor.name }}</h3><span class=\"detail\">{{ distributor.objectId }}</span><p>{{ distributor.address }}</p><button class=\"btn btn-outline btn-default create\" ng-click=\"vm.addUser(distributor)\"><i class=\"fa fa-plus\"></i> Add User</button></div><div class=\"col-md-12\"><hr></div></div></div></div></div>");
$templateCache.put("app/modules/settings/misc/settings.misc.html","<div class=\"wrapper wrapper-content animated fadeIn\"><h1>Misc</h1><div><a ng-click=\"vm.fixInventoryProducts()\">Fix Inventory Products</a></div><div><a ng-click=\"vm.fixTemplateProducts()\">Fix Template Products</a></div><div><a ng-click=\"vm.deleteDuplicateProducts()\">Delete Duplicate Products</a></div><div><a ng-click=\"vm.copyGuides()\">Copy Guides</a></div><div><a ng-click=\"vm.copyProducts()\">Copy Products</a></div><div><a ng-click=\"vm.copyTrays()\">Copy Trays</a></div><div><a ng-click=\"vm.copyLayers()\">Copy Layers</a></div></div>");
$templateCache.put("app/modules/settings/personal/personal.html","<div class=\"wrapper wrapper-content animated fadeIn\"><div class=\"row\" style=\"margin-top:20px;\"><div class=\"col-sm-4\"><md-input-container class=\"md-block\"><label>Email</label> <input ng-model=\"vm.account.email\" disabled=\"\"></md-input-container></div><div class=\"col-sm-4\"><md-input-container class=\"md-block\"><label>Account Type</label> <input ng-model=\"vm.account.accountType\" disabled=\"\"></md-input-container></div></div><div class=\"row\"><div class=\"col-sm-4\"><md-input-container class=\"md-block\"><label>Name</label> <input ng-model=\"vm.account.name\" required=\"\"></md-input-container></div><div class=\"col-sm-4\"><md-input-container class=\"md-block\"><label>Phone</label> <input ng-model=\"vm.account.phone\" required=\"\"></md-input-container></div></div><div class=\"row\"><div class=\"col-sm-1\"><button class=\"btn btn-sm btn-outline btn-primary\" ng-click=\"vm.save()\">Save</button></div></div></div>");}]);